<div *ngIf="group" [formGroup]="group" class="custom-control custom-switch">
  <input (ngModelChange)="change($event)" [checked]="modelAttribute"
         [disabled]="group.get(fcName).disabled"
         [formControlName]="fcName"
         [id]="fcName + i"
         [name]="fcName + i"
         class="custom-control-input green"
         type="checkbox">
  <label [for]="fcName+i" class="custom-control-label">{{label}} <span [ngClass]="{'star': required}"></span></label>
</div>
<div *ngIf="!group" class="custom-control custom-switch">
  <input [(ngModel)]="modelAttribute"
         (change)="changeEvent($event)"
         [disabled]="disabled"
         [id]="fcName + i"
         [name]="fcName + i"
         class="custom-control-input green"
         type="checkbox">
  <label [for]="fcName+i" class="custom-control-label">{{label}} <span [ngClass]="{'star': required}"></span></label>
</div>
