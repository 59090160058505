import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

export const isNotNullOrUndefined = <T>(input: null | undefined | T): input is T =>
  input !== null && input !== undefined;

export const isNullOrUndefined = <T>(input: null | undefined | T): input is T =>
  input === null || input === undefined;

export const trim = (input: string): string => (input && input !== '' ? input.trim() : '');

export const getEnumKeysAsNumber = <T>(input: T): number[] =>
  Object.keys(input)
    .filter((item) => !isNaN(Number(item)))
    .map((item) => Number(item));

export const getMobileToken = (): string => {
  return localStorage.getItem(
    'lpUe89U0cdHjA680yJSOBC75T8EaZB9o20oR4eeq4CBV4r6T68zim0k4Gmx87MspPP7JQ2c5f49BAj6Y' +
    'E7y3i5an5kViTJ6xU1J4NqJ2WW4g0m3KNubh5IWNCDL3EK0Z5pPrnd4UBAg566Q7996i43SyQk3QZ3kJ4AgoB68C0Q6t2VZ5ulMaNKbwTR9PefD9bg5Bg' +
    'nH8sFOhfi18Xu8bxwk10HzoGxInk0622mX9X6xvGNbH6T0ajGz4GZL9z456Bqf15rz168pzRiGM60b35Uh0xF8476T560iAi2ofDB3g1kaPpyVXcTbApN' +
    'ME52J9My0Gl2uu6I84V7tGK6w8173652c6GsEsn0jKprW5WV2uxjv3n11uMIp6cd6tIBtJe1RCELyttxNjzp1GWMCdA04ta3K95d5AipiDoOv3CC9nB55' +
    '9zSoFrhkl8eNJOSTGHp8mF0xws8kh84qL4Q54k6k8EH5K51hQjT6tNrRQP8641LL102n02x7LPV77hSZ2'
  );
};


export function cloneFormGroup(formGroup: UntypedFormGroup): UntypedFormGroup {
  const newFormGroup = new UntypedFormGroup({});

  Object.keys(formGroup.controls).forEach(controlName => {
    const control = formGroup.controls[controlName];

    if (control instanceof UntypedFormGroup) {
      // If the control is a FormGroup, recursively deep copy it
      newFormGroup.addControl(controlName, cloneFormGroup(control));
    } else if (control instanceof UntypedFormArray) {
      // If the control is a FormArray, recursively deep copy it
      newFormGroup.addControl(controlName, cloneFormArray(control));
    } else if (control instanceof UntypedFormControl) {
      // If the control is a FormControl, create a new FormControl with the same value and validators
      newFormGroup.addControl(controlName, new UntypedFormControl(control.value, control.validator, control.asyncValidator));
    }
  });

  return newFormGroup;
}

export function cloneFormArray(formArray: UntypedFormArray): UntypedFormArray {
  const newFormArray = new UntypedFormArray([]);

  formArray.controls.forEach(control => {
    if (control instanceof UntypedFormGroup) {
      // Recursively deep copy FormGroup inside FormArray
      newFormArray.push(cloneFormGroup(control));
    } else if (control instanceof UntypedFormArray) {
      // Recursively deep copy FormArray inside FormArray
      newFormArray.push(cloneFormArray(control));
    } else if (control instanceof UntypedFormControl) {
      // Create a new FormControl with the same value and validators
      newFormArray.push(new UntypedFormControl(control.value, control.validator, control.asyncValidator));
    }
  });

  return newFormArray;
}
