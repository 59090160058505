import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {AlertService} from 'src/app/services/alert.service';
import {Observable, of} from 'rxjs';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {FormatService} from 'src/app/services/format.service';
import {TypeContactBinomeEnum} from 'src/app/model/enums/type-contact-binome.enum';
import {DurationBinomeEnum} from 'src/app/model/enums/duration-binome.enum';
import {debounceTime} from 'rxjs/operators';
import {FilterUser} from 'src/app/model/filter/filter-relation-binome/filter-user.model';
import {YoungWebservice} from 'src/app/services/webservices/young.webservice';
import {SpeakerService} from 'src/app/services/webservices/speaker.webservice';
import {RelationBinome} from 'src/app/model/user/relation-binome.model';
import {RelationBinomeWebservice} from 'src/app/services/webservices/relation-binome.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {CustomDateAdapter} from 'src/app/utils/custom-date-adapter';
import {FilterRelationBinomeIntervenantDTO} from 'src/app/model/filter/filter-relation-binome/filter-relation-binome-intervenant.dto';
import {FilterRelationBinomeYoungDTO} from 'src/app/model/filter/filter-relation-binome/filter-relation-binome-young.dto';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {InputTextComponent} from '../../form-parts/form-items/input-text/input-text.component';
import {SelectComponent} from '../../form-parts/form-items/select/select.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AsyncPipe, DatePipe, NgClass, NgFor, NgIf} from '@angular/common';
import {getEnumKeysAsNumber, isNotNullOrUndefined} from 'src/app/utils/utils.static';
import {TypeBinomeEnum} from 'src/app/model/enums/type-binome.enum';
import {SimpleYoungDTO} from 'src/app/model/dto/simple-young.dto';
import {UserWebservice} from 'src/app/services/webservices/user.webservice';
import {User} from 'src/app/model/user/user.model';

@Component({
  selector: 'app-dialog-add-relation-speaker',
  templateUrl: './dialog-add-relation-speaker.component.html',
  styleUrls: ['./dialog-add-relation-speaker.component.scss'],
  providers: [{provide: DateAdapter, useClass: CustomDateAdapter}],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    FormsModule,
    MatAutocompleteModule,
    MatTooltipModule,
    NgClass,
    NgFor,
    MatOptionModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    SelectComponent,
    InputTextComponent,
    MatInputModule,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe,
    DatePipe
  ]
})
export class DialogAddRelationSpeakerComponent extends Destroyed implements OnInit, OnDestroy {
  result = false;

  relationBinome: RelationBinome;
  public filteredIntervenantObservable: Observable<FilterRelationBinomeIntervenantDTO[]>;
  public filteredYoungObservable: Observable<FilterRelationBinomeYoungDTO[]>;

  public intervenantFormControl: FormControl<string> = new FormControl<string>('');
  public youngFormControl: FormControl<string> = new FormControl<string>('');

  relationBinomeFormGroup: UntypedFormGroup;

  typeContactForSelect = [];
  typeContactBinomeForSelect = [];
  durationBinomeForSelect = [];

  youngChecker = false;
  intervenantChecker = false;
  dureeBinomeChecker = false;
  typeContactBinomeChecker = false;
  typeBinomeChecker = false;

  filteredIntervenant: FilterUser;
  filteredYoung: FilterUser;

  id: number;
  isYoung: boolean;
  isIntervenant: boolean;

  isUpdate = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddRelationSpeakerComponent>,
    private readonly formatService: FormatService,
    private readonly fb: UntypedFormBuilder,
    private readonly alertService: AlertService,
    private readonly youngService: YoungWebservice,
    private readonly speakerService: SpeakerService,
    private readonly relationBinomeWS: RelationBinomeWebservice,
    private readonly youngWebservice: YoungWebservice,
    private readonly userWebservice: UserWebservice
  ) {
    super();
    this.initTypeBinome();
    this.initType();
    this.initDurationBinome();

    this.relationBinomeFormGroup = this.fb.group({
      dateBeginBinome: [null],
      typeBinome: [null, Validators.required],
      typeContactBinome: [null, Validators.required],
      dureeBinome: [null, Validators.required],
      jeune: [null, Validators.required],
      intervenant: [null, Validators.required],
      dateSignatureMoralEngagementContract: [null],
      dateSignatureSponsorshipActionPlan: [null],
      suivi: [null],
      dateEndBinome: [null],
      commentaryEndBinome: [null]
    });

    this.setValidators();
  }

  get fRelationBinome() {
    return this.relationBinomeFormGroup.controls;
  }

  get isParrainageYear() {
    return (
      this.fRelationBinome.typeBinome.value === 0 &&
      this.fRelationBinome.dureeBinome.value &&
      (this.fRelationBinome.dureeBinome.value === 3 || this.fRelationBinome.dureeBinome.value === 2)
    );
  }

  ngOnInit() {
    this.filteredIntervenant = new FilterUser();
    this.filteredYoung = new FilterUser();

    this.fRelationBinome.dureeBinome.valueChanges.pipe(this.untilDestroyed()).subscribe(() => {
      if (this.isParrainageYear) {
        this.fRelationBinome.dateSignatureMoralEngagementContract.enable();
        this.fRelationBinome.dateSignatureSponsorshipActionPlan.enable();
      } else {
        this.fRelationBinome.dateSignatureMoralEngagementContract.disable();
        this.fRelationBinome.dateSignatureSponsorshipActionPlan.disable();
      }
    });

    this.initValue();

    this.youngFormControl.valueChanges
      .pipe(debounceTime(500), this.untilDestroyed())
      .subscribe((res) => {
        this._filterYoungList(res);
      });

    this.intervenantFormControl.valueChanges
      .pipe(debounceTime(500), this.untilDestroyed())
      .subscribe((res) => {
        this._filterIntervenantList(res);
      });
  }

  setValidators() {
    this.fRelationBinome.typeBinome.valueChanges.pipe(this.untilDestroyed()).subscribe(() => {
      if (!(TypeBinomeEnum.REFERENT_EXTERNE === this.fRelationBinome.typeBinome.value)) {
        this.fRelationBinome.dureeBinome.setValidators(Validators.required);
        this.fRelationBinome.typeContactBinome.setValidators(Validators.required);
      } else {
        this.fRelationBinome.dureeBinome.clearValidators();
        this.fRelationBinome.typeContactBinome.clearValidators();
      }
      this.fRelationBinome.dureeBinome.updateValueAndValidity();
      this.fRelationBinome.typeContactBinome.updateValueAndValidity();
    });
  }

  initValue() {
    if (this.relationBinome && this.relationBinome.id > 0) {
      this.relationBinomeWS
        .getRelationBinomeById(this.relationBinome.id)
        .pipe(this.untilDestroyed())
        .subscribe((value: RelationBinome) => {
          this.relationBinome = value;
          this.isYoung = true;
          this.isIntervenant = true;
          this.isUpdate = true;

          this.fRelationBinome.jeune.setValue(value.user.id);
          this.filteredYoung = new FilterUser();
          this.filteredYoung.name = value.user.firstName + ' ' + value.user.lastName;
          this.youngFormControl.setValue(value.user.firstName + ' ' + value.user.lastName);

          this.fRelationBinome.intervenant.setValue(value.relative.id);
          this.filteredIntervenant = new FilterUser();
          this.filteredIntervenant.name = value.relative.firstName + ' ' + value.relative.lastName;
          this.intervenantFormControl.setValue(
            value.relative.firstName + ' ' + value.relative.lastName
          );

          this.setForms();
        });
    } else {
      if (this.id !== undefined) {
        if (this.isYoung) {
          this.youngWebservice
            .getYoung(this.id)
            .pipe(this.untilDestroyed())
            .subscribe((value: SimpleYoungDTO) => {
              this.fRelationBinome.jeune.setValue(value.user.id);
              this.filteredYoung = new FilterUser();
              this.filteredYoung.name = value.user.firstName + ' ' + value.user.lastName;
            });
        }
        if (this.isIntervenant) {
          this.userWebservice
            .getUserById(this.id)
            .pipe(this.untilDestroyed())
            .subscribe((value: User) => {
              this.fRelationBinome.intervenant.setValue(value.id);
              this.filteredIntervenant = new FilterUser();
              this.filteredIntervenant.name = value.firstName + ' ' + value.lastName;
            });
        }
      }
    }
  }

  getIntervenant(event) {
    this.fRelationBinome.intervenant.setValue(event.id);
  }

  getYoung(event) {
    this.fRelationBinome.jeune.setValue(event.id);
  }

  displayFn(item): string {
    return item && item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : '';
  }

  checkBeforeSubmit() {
    if (this.fRelationBinome.typeBinome.invalid) {
      this.typeBinomeChecker = true;
    }
    if (this.fRelationBinome.jeune.invalid) {
      this.youngChecker = true;
    }
    if (this.fRelationBinome.intervenant.invalid) {
      this.intervenantChecker = true;
    }
    if (this.fRelationBinome.dureeBinome.invalid || !this.fRelationBinome.dureeBinome.value) {
      this.dureeBinomeChecker = true;
    }

    if (this.fRelationBinome.typeBinome.invalid) {
      this.typeContactBinomeChecker = true;
    }
  }

  validSelection() {
    this.checkBeforeSubmit();
    if (this.relationBinomeFormGroup.valid) {
      this.relationBinome = this.initRelationBinomeWithFormGroup();
      this.relationBinomeWS
        .postRelationBinome(this.relationBinome)
        .pipe(this.untilDestroyed())
        .subscribe({
          next: () => this.dialogRef.close()
        });
    }
  }

  updateSelection() {
    this.checkBeforeSubmit();
    if (this.relationBinomeFormGroup.valid) {
      const id = this.relationBinome.id;
      this.relationBinome = this.initRelationBinomeWithFormGroup();
      this.relationBinome.id = id;
      this.relationBinomeWS
        .updateRelationBinome(this.relationBinome)
        .pipe(this.untilDestroyed())
        .subscribe({
          next: () => this.dialogRef.close()
        });
    }
  }

  cancelSelection() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.alertService.clear();
  }

  initRelationBinomeWithFormGroup() {
    const relationBinome = new RelationBinome();
    relationBinome.dateBeginBinome = this.fRelationBinome.dateBeginBinome.value;
    relationBinome.idYoung = this.fRelationBinome.jeune.value;
    relationBinome.idIntervenant = this.fRelationBinome.intervenant.value;
    relationBinome.typeBinome = this.fRelationBinome.typeBinome.value;
    relationBinome.type = this.fRelationBinome.typeContactBinome.value;
    relationBinome.dureeBinome = this.fRelationBinome.dureeBinome.value;
    relationBinome.dateSignatureMoralEngagementContract =
      this.fRelationBinome.dateSignatureMoralEngagementContract.value;
    relationBinome.dateSignatureSponsorshipActionPlan =
      this.fRelationBinome.dateSignatureSponsorshipActionPlan.value;
    relationBinome.suivi = this.fRelationBinome.suivi.value;
    relationBinome.dateEndBinome = this.fRelationBinome.dateEndBinome.value;
    relationBinome.commentaryEndBinome = this.fRelationBinome.commentaryEndBinome.value;

    return relationBinome;
  }

  private _filterIntervenantList(value) {
    if (typeof value === 'string') {
      let filterValue = this.formatService.clearAccent(value.toLowerCase().trim());
      const valueToSend = new FilterUser();
      filterValue = filterValue.replace(/\s/g, '');
      valueToSend.name = filterValue;
      if (value.length > 1) {
        this.speakerService
          .getAllSpeakersByName(valueToSend)
          .pipe(debounceTime(1000))
          .subscribe({
            next: (res) => {
              this.filteredIntervenantObservable = of(res);
            }
          });
      } else {
        this.filteredIntervenantObservable = of([]);
      }
    }
  }

  private _filterYoungList(value) {
    if (typeof value === 'string') {
      let filterValue = this.formatService.clearAccent(value.toLowerCase().trim());
      const valueToSend = new FilterUser();
      filterValue = filterValue.replace(/\s/g, '');
      valueToSend.name = filterValue;
      valueToSend.program = 5;
      if (value.length > 1) {
        this.youngService
          .getAllYoungsByName(valueToSend)
          .pipe(debounceTime(1000))
          .subscribe((res) => {
            this.filteredYoungObservable = of(res);
          });
      } else {
        this.filteredYoungObservable = of([]);
      }
    }
  }

  private initTypeBinome() {
    getEnumKeysAsNumber(TypeBinomeEnum).forEach((i) =>
      this.typeContactForSelect.push({
        id: i,
        name: TypeBinomeEnum.format(i)
      })
    );
  }

  private initType() {
    getEnumKeysAsNumber(TypeContactBinomeEnum).forEach((i) =>
      this.typeContactBinomeForSelect.push({
        id: i,
        name: TypeContactBinomeEnum.format(i)
      })
    );
  }

  private initDurationBinome() {
    getEnumKeysAsNumber(DurationBinomeEnum).forEach((i) =>
      this.durationBinomeForSelect.push({
        id: i,
        name: DurationBinomeEnum.format(i)
      })
    );
  }

  private setForms() {
    if (this.relationBinome) {
      this.handleRelationBinome();
    }
  }

  private handleRelationBinome() {
    if (this.relationBinome.dateBeginBinome) {
      this.fRelationBinome.dateBeginBinome.setValue(this.relationBinome.dateBeginBinome);
    }
    if (this.relationBinome.dateEndBinome) {
      this.fRelationBinome.dateEndBinome.setValue(this.relationBinome.dateEndBinome);
    }
    if (isNotNullOrUndefined(this.relationBinome.typeBinome)) {
      this.fRelationBinome.typeBinome.setValue(this.relationBinome.typeBinome);
    }
    if (isNotNullOrUndefined(this.relationBinome.type)) {
      this.fRelationBinome.typeContactBinome.setValue(this.relationBinome.type);
    }
    if (isNotNullOrUndefined(this.relationBinome.dureeBinome)) {
      this.fRelationBinome.dureeBinome.setValue(this.relationBinome.dureeBinome);
    }
    if (this.relationBinome.dateSignatureSponsorshipActionPlan) {
      this.fRelationBinome.dateSignatureSponsorshipActionPlan.setValue(
        this.relationBinome.dateSignatureSponsorshipActionPlan
      );
    }
    if (this.relationBinome.dateSignatureMoralEngagementContract) {
      this.fRelationBinome.dateSignatureMoralEngagementContract.setValue(
        this.relationBinome.dateSignatureMoralEngagementContract
      );
    }
    if (this.relationBinome.commentaryEndBinome) {
      this.fRelationBinome.commentaryEndBinome.setValue(this.relationBinome.commentaryEndBinome);
    }

    if (this.relationBinome.suivi) {
      this.fRelationBinome.suivi.setValue(this.relationBinome.suivi);
    }
  }

  protected readonly TypeBinomeEnum = TypeBinomeEnum;
}
